<template>
    <footer>
        <div class="container">
            <div class="row">
                <hr />
            </div>
            <div class="row">
                <div class="col">
                    <a href="http://www.computational.bio/" target="_blank"><img src="@/assets/logo-cb.svg" alt="Computational Biology" style="height: 120px;"/></a>
                </div>
                <div class="col">
                    <a href="http://www.denbi.de/" target="_blank"><img src="@/assets/logo-denbi.svg" alt="deNBI" style="height: 80px;"/></a>
                </div>
                <div class="col">
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
hr {
  margin-bottom: 0em;
}
img {
  height: 8em;
}
</style>
